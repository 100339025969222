
interface Evidencia {
  url: string;
  categoria: string;
}

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  onBeforeUpdate,
  computed,
} from "vue";
import { saveToken } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import emitter from "tiny-emitter/instance";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "Laudo",
  components: {},
  setup() {
    const route = useRouter();
    const model = reactive({
      perguntas: [
        { text: "Identificação", value: "Identificacao" },
        { text: "Periféricos", value: "Perifericos" },
        { text: "Pintura", value: "Pintura" },
        { text: "Estrutura", value: "Estrutura" },
        { text: "Conservação Externa", value: "ConservacaoExterna" },
        { text: "Conservação Interna", value: "ConservacaoInterna" },
        { text: "Funcionamento", value: "Funcionamento" },
      ],
      analiseId: computed(() => {
        return route.currentRoute.value.params.analiseId;
      }),
    });

    const cadastro = ref<any>({});
    const documentos = ref<any>([]);

    let imageList: Array<Evidencia> = [];
    const previewList = ref<any>([]);
    const previewDocsList = ref<any>([]);
    const previewImage = ref("media/avatars/carros.jpg");
    const progressBars = ref<any>([]);
    const progressBarDocumento = ref<HTMLElement>();
    const inputs = ref<any>([]);
    const inputDocumento = ref<any>();
    const imagem = ref<any>();
    let progressBar;

    onBeforeUpdate(() => {
      inputs.value = [];
      progressBars.value = [];
    });

    const onFotoPrincipalAdd = (event) => {
      cadastro.value.imagem = null;
      const input = event.target;
      let count = input.files.length;
      let index = 0;
      if (input.files) {
        while (count--) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            previewImage.value = e.target.result;
          };
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    };

    const onFileAdd = (event) => {
      const input = event.target;
      let count = input.files.length;
      let index = 0;
      if (input.files) {
        while (count--) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            previewList.value.push(e.target.result);
          };
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    };

    const onDocumentoAdd = (event) => {
      const input = event.target;
      let count = input.files.length;
      let index = 0;
      if (input.files) {
        while (count--) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            previewDocsList.value.push(input.files[index].name);
            index++;
          };
          reader.readAsDataURL(input.files[index]);
        }
      }
    };

    const onUpload = (status) => {
      const percent = (status.loaded / status.total) * 100;
      const progress = Math.round(percent);
      progressBar.setAttribute("style", "width: " + progress.toString() + "%");
      if (progress === 100) {
        Swal.fire({
          text: "Imagens enviadas com sucesso",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok ",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
      }
    };

    const onUploadDocumento = (status) => {
      const percent = (status.loaded / status.total) * 100;
      const progress = Math.round(percent);
      progressBarDocumento.value?.setAttribute("style", "width: " + progress.toString() + "%");
      // if (progress === 100) {
      //   Swal.fire({
      //     text: "Documentos enviadas com sucesso",
      //     icon: "success",
      //     buttonsStyling: false,
      //     confirmButtonText: "Ok ",
      //     customClass: {
      //       confirmButton: "btn fw-bold btn-light-success",
      //     },
      //   });
      // }
    };

    const removeImage = () => {
      cadastro.value.imagem = null;
      previewImage.value = "media/avatars/carros.png";
    };

    const salvarParecerFinaleFoto = () => {
      const formData = new FormData();
      formData.append("imagem", imagem.value.files[0]);
      formData.append("analiseId", model.analiseId.toString());
      formData.append("parecer", cadastro.value.parecerFinal);

      ApiService.post("analise/parecer-final", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        Swal.fire({
          text: "Informações salvas com sucesso",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
      });
    };

    const enviar = (categoria: string, index) => {
      progressBar = progressBars.value[index];
      const formData = new FormData();

      Array.prototype.forEach.call(inputs.value[index].files, (file) => {
        formData.append("imagens", file);
      });

      formData.append("categoria", categoria);
      formData.append("analiseId", model.analiseId.toString());

      ApiService.post("analise/upload", formData, {
        onUploadProgress: onUpload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
          Swal.fire({
            text: `Evidências adicionadas com sucesso!`,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, proximo!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          });
        })
        .catch(() => {
          Swal.fire({
            text: "Não foi possível registrar as informações",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok :(",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        });
    };

    const enviarDocumentos = () => {
      const formData = new FormData();

      Array.prototype.forEach.call(inputDocumento.value.files, (file) => {
        formData.append("documentos", file);
      });

      formData.append("analiseId", model.analiseId.toString());

      ApiService.post("documento/cadastrar", formData, {
        onUploadProgress: onUploadDocumento,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
          Swal.fire({
            text: `Documentos adicionados com sucesso!`,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, proximo!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          });
        })
        .catch(() => {
          Swal.fire({
            text: "Não foi possível registrar as informações",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok :(",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        });
    };

    const categoriaFilter = (categoria) => {
      return imageList.filter((x) => {
        return x.categoria === categoria;
      });
    };

    const removeImagem = (index) => {
      previewDocsList.value.splice(index, 1);
    };

    const removeDocumento = (id, index) => {
      documentos.value.splice(index, 1);
      ApiService.delete("/documento/excluir/" + id).then(({ data }) => {
        console.log("data", data);
      });
    };

    onMounted(() => {
      ApiService.get(`analise?id=${model.analiseId}`).then(({ data }) => {
        cadastro.value = data;
      });
      ApiService.get(`analise/evidencias?id=${model.analiseId}`).then(
        ({ data }) => {
          imageList = data;
        }
      );
      ApiService.get(`analise/documentos?id=${model.analiseId}`).then(
        ({ data }) => {
          documentos.value = data;
        }
      );
    });

    return {
      model,
      cadastro,
      documentos,
      imagem,
      previewImage,
      previewList,
      previewDocsList,
      onFotoPrincipalAdd,
      removeImage,
      onFileAdd,
      onDocumentoAdd,
      enviar,
      enviarDocumentos,
      inputs,
      inputDocumento,
      categoriaFilter,
      progressBars,
      progressBarDocumento,
      salvarParecerFinaleFoto,
      removeDocumento
    };
  },
});
